var $ = require("jquery");
window.jQuery = $;

const qpcSearchAutocomplete = () => {

  $("input#keyword").on('keyup',function() {
    if ($(this).val().length > 2) {
      $("#datafetch").show('fast');
    } else {
      $("#datafetch").hide('fast');
    }
  });

  $('.search-button-sidebar').on("click",function(e){
    e.stopPropagation();
    $('.search-product').toggleClass('open-form');
    $('body').toggleClass('overflowBody');
  });

  $(document).on('click', (e) => {
    var container = $(".search-product-padding");
    if (!container.is(e.target)
        && container.has(e.target).length === 0) 
    {
    $('.search-product').removeClass('open-form');    
    $('body').removeClass('overflowBody');

   }
 });

 $( document ).on('keydown', (event) => {
  if ((event.key === "Escape") 
    && ($(".search-product").hasClass("open-form"))){
    $(".search-product").removeClass("open-form");
    $('body').removeClass('overflowBody');

  }
  if (event.which == '13') {
    event.preventDefault();
  }
});


}


export { qpcSearchAutocomplete };