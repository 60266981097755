import barba from '@barba/core';
import Swiper from 'swiper/bundle';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Isotope from "isotope-layout";
import { qpcSwiper } from './inc/qpc-swiper.js';
import { qpcTransitions } from './inc/qpc-transitions.js';
import { qpcInteractions } from './inc/qpc-interactions.js';
import { qpcSearchAutocomplete } from './inc/qpc-search-autocomplete.js';
//import { qpcAcfGoogleMaps } from './inc/qpc-acf-googlemaps.js';
import { qpcHorizontalScrollText } from './inc/qpc-horizontal-scroll-text.js';


var jQueryBridget = require('jquery-bridget');
jQueryBridget( 'isotope', Isotope, $ );

var $ = require("jquery");
window.jQuery = $;
require("@fancyapps/fancybox");

function delay(n) {
  n = n || 2000;
  return new Promise(done => {
    setTimeout(() => {
      done();
    }, n);
  });
}

gsap.registerPlugin(ScrollTrigger);

gsap.config({
  nullTargetWarn: false,
  trialWarn: false,
});

function hGallery(){

  $( function() {
   var qpcGalleryWrapper = $('.qpc-horizontal-gallery');
   var qpcHgallery = $('.qpc-h-gallery');
   var qpcHgalleryLength = $(qpcHgallery).width() - $( window ).innerWidth();
    var tl = gsap.timeline();
     if (qpcHgallery)  {
      tl.to(qpcHgallery, {
        x: '-' + qpcHgalleryLength,
        scrollTrigger: {
          trigger: qpcGalleryWrapper,
          start: 'center center',
          end: '+=' + qpcHgalleryLength, 
          pin: true,
          scrub: 0.1,
        }
      })
     }
  })
}

$( function() {

//barba.use(barbaCss);
barba.init({
  debug: false,
  sync: true,
  cacheIgnore: false,
  prefetchIgnore: false,
  timeout: 30000,
  prevent: ({ el }) => el.classList && el.classList.contains('no-barba'),

  transitions: [{
      name: 'opacity-transition',
      leave(data) {
        return gsap.to(data.current.container, {
          duration: 0.5,
          y: -100,
          autoAlpha: 0,
          stagger: 0.2,
          delay: 0.2
        });
      },
      enter(data) {
        return gsap.from(data.next.container, {
          autoAlpha: 0
        });
      }
    }]
});


// Setto le <body> classes, barba.hooks.afterLeave rimpiazza il vecchio Barba.Dispatcher.on("newPageReady"...
barba.hooks.afterLeave((data) => {
  var nextHtml = data.next.html;
  var response = nextHtml.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', nextHtml)
  var bodyClasses = $(response).filter('notbody').attr('class')
  $("body").attr("class", bodyClasses);
  $("html, body").animate({ scrollTop: 0 }, 700);
});

barba.hooks.after(() => {

  var vids = document.querySelectorAll(".swiper-head video"); vids.forEach(vid => { var playPromise = vid.play(); if (playPromise !== undefined) { playPromise.then(_ => {}).catch(error => {}); }; });

});

// Carico gli script di terze parti
barba.hooks.afterEnter(() => {

  //if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
  if(/iPhone|iPad/i.test(navigator.userAgent)){

  // vero per il mobile

  $('.popup-menu-info').css('height', '-webkit-fill-available');

  }else{

  }

  qpcHorizontalScrollText();
  hGallery();
  qpcSwiper();
  qpcInteractions();
  qpcSearchAutocomplete();
  //qpcAcfGoogleMaps();

  // Sposto il pre-header dentro il menu lateral per il mobile
    $(window).ready(function() {
      var wi = $(window).width();
    //  $(".container-pre-header.qpc-container").prependTo(".wrap-header");  //default
      setTimeout(() => window.dispatchEvent(new Event('resize')), 100);

      $(window).on('resize', function() {
          var wi = $(window).width();

          if (wi <= 767){
            $(".container-pre-header.qpc-container").insertAfter(".popout .menu-main-menu-container");
            $(".info-menu, .icon-menu").insertAfter(".popup-menu-info");
              }
          else {
            $(".container-pre-header.qpc-container").prependTo(".wrap-header");
              }
      });
  });

  // Disabilito Barba su questi elementi
  $(".quick-info, .wrap-accordion a, .wp-block-gallery a, .qpc-horizontal-gallery a, .wpml-ls-link, .ab-item, #wp-toolbar a, #wpadminbar a, .toggle, .popout, .extra-menu a, #wp-toolbar a").addClass("no-barba");
  //$('.wp-block-columns, .wp-block-group').addClass('qpc-container');
  //var blank = 'noopener';
  var blank = '_blank';
  $('a').filter('a[rel^="'+blank+'"]').addClass('no-barba');

  $(".qpc-wrap-table .wp-block-group__inner-container").click(function() {
    $(this).find('.has-fixed-layout').toggleClass("open");
    $(this).find('h2').toggleClass("show-label");
  });

  // Filtro i docenti
  $("#inputSearch").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $(".qpc-filter").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });

// aggiungo gli stili hover ai vai menu
 $('ul.menu-qpc-class > li, #menu-menu-informazioni-utili > li, .popup-menu-info-container .col-ds a ').addClass('cta-button cta-button-underline-reverse cta-button-uppercase');
 $('.qpc-cta-pdf a').addClass('cta-button cta-button-outline cta-uppercase');
 $('.wrap-info-docente a, .sotto-titolo-vetrina a, .wp-block-group__inner-container p a, #footer-sidebar3 a').addClass('cta-button cta-button-underline');
 $('.menu-pre-header-container li').addClass('cta-button cta-button-underline-reverse');

  $('.gallery-item, .qpc-horizontal-gallery').each(function() {
    $(this).find(".gallery-icon a, .wp-block-image a, .qpc-h-gallery a").attr('data-fancybox', 'group-Gallery' + $(this).parent().attr('id'));
    var caption = $(this).find('.wp-caption-text').text();
    //alert(caption);
    $(this).find('.immagine-gallery').attr('title', caption);
  });


       $('[data-fancybox="quick-info"]').fancybox({
    touch : {
  		vertical : true
  	},
    "smallBtn" : false,
     });


   $('.info-menu').on('click', function()  {
       $('.popup-menu-info').toggleClass('popup-menu-info-slide');
       $('body').toggleClass('body-overflow-h');
    });

    $('.icon-close').on( 'click', function()  {
      $('.popup-menu-info').removeClass('popup-menu-info-slide');
      $('body').removeClass('body-overflow-h');
    });

    $(document).keyup(function(e) {
      if (e.keyCode == 27) {
      $('.popup-menu-info').removeClass('popup-menu-info-slide');
      $('body').removeClass('body-overflow-h');
    }   // esc
  });


  $(".toggle").on('click', function(event) {
    $('.popout').toggleClass('popout-slide');
    $('.button_container ').toggleClass('active');
    $(".menu-main-menu-container").toggleClass("popout-active");
    event.stopImmediatePropagation(); // lo utilizzo per barba altrimenti il toggle viene richiamato 2 volte
});

  // pareggio l'altezza del titolo nel caso andasse su 2 righe
  var maxHeight = 0;
   $(".wrap-box-slide span:first-child").each(function() {
     if ($(this).outerHeight() > maxHeight) {
       maxHeight = $(this).outerHeight();
     }
   }).height(maxHeight);

   var maxHeight = 0;
    $(".wrap-box-slide p").each(function() {
      if ($(this).outerHeight() > maxHeight) {
        maxHeight = $(this).outerHeight();
      }
    }).height(maxHeight);

   var maxHeight = 0;
    $(".mc-description-title").each(function() {
      if ($(this).outerHeight() > maxHeight) {
        maxHeight = $(this).outerHeight();
      }
    }).height(maxHeight);

    var maxHeight = 0;
     $(".news-home a").each(function() {
       if ($(this).outerHeight() > maxHeight) {
         maxHeight = $(this).outerHeight();
       }
     }).height(maxHeight);

     var maxHeight = 0;
      $(".news-home p").each(function() {
        if ($(this).outerHeight() > maxHeight) {
          maxHeight = $(this).outerHeight();
        }
      }).height(maxHeight);

  // Prendo l'elemento tab__content più alto
  let height = -1;
  $('.tab__content').each(function() {
    height = height > $(this).outerHeight() ? height : $(this).outerHeight();
    $(this).css('position', 'absolute');
  });
  // Setto l'altezza del tab prendendo l'elemento più alto di tab__content
  $('[data-tabs]').css('height', height + 100 + 'px');

  $("#timepicker").prop('type', 'time');


  // quick search regex
  var qsRegex;

  // init Isotope
  var $grid = $('.isotope').isotope({
    itemSelector: '.isotope-item',
    layoutMode: 'fitRows',
    filter: function(data) {
      return qsRegex ? $(data).text().match( qsRegex ) : true;
    }
  });

  // use value of search field to filter
  var $quicksearch = $('.quicksearch').on('keyup', debounce( function() {
    qsRegex = new RegExp( $quicksearch.val(), 'gi' );
    $grid.isotope();
  }, 200 ) );

  // debounce so filtering doesn't happen every millisecond
  function debounce( fn, threshold ) {
    var timeout;
    threshold = threshold || 100;
    return function debounced() {
      clearTimeout( timeout );
      var args = arguments;
      var _this = this;
      function delayed() {
        fn.apply( _this, args );
      }
      timeout = setTimeout( delayed, threshold );
    };
  }


  // su mobile risolvo il click della tendina nel caso ci fosse assegnato un url
  $(".menu-qpc-class li.menu-item-has-children > a").on('click', function(event) {
		event.preventDefault();
	});

}); //afterEnter

});

// Evito il ricaricamento di Barba nella pagina corrente
var links = document.querySelectorAll('a[href]');
var cbk = function(e) {
  if (e.currentTarget.href === window.location.href) {
    e.preventDefault();
    e.stopPropagation();
  }
};
for (var i = 0; i < links.length; i++) {
  links[i].addEventListener('click', cbk);
}
