import Swiper from "swiper/bundle";
var $ = require("jquery");
window.jQuery = $;

const qpcSwiper = (qpcswipe) => {

  var swiper1 = new Swiper('.swiper1', {

    speed: 1,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination1',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    on: {
      slideChange: function() {
        $(".swiper-slide.swiper-slide-active").removeClass("mos");

      },
      init: function() {
        $(".swiper-slide.swiper-slide-active").addClass("mos");

      },
      transitionStart: function() {
        $(".swiper-slide.swiper-slide-active").addClass("mos");
      },
    }
  });

  var swiperGrid = new Swiper('.swiperGrid', {
    speed: 600,
    //loop: true,
    //autoHeight: true,
    calculateHeight:true,
    slidesPerColumn: 2,
    slidesPerGroup :3,
    slidesPerColumnFill: 'row',
    autoplay: {
      delay: 4500,
      disableOnInteraction: false
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 0,
        slidesPerColumn: 2,
        slidesPerGroup :1
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
        slidesPerColumn: 2,
        slidesPerGroup :1
      }
    },
    // init: false,
    pagination: {
      el: '.swiper-swiperGrid',
      clickable: true,
      dynamicBullets: true
    }
  });

  var swiper2 = new Swiper('.swiper2', {
    speed: 600,
    //loop: true,
    autoplay: {
      delay: 4500,
      disableOnInteraction: true
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      320: {
        slidesPerView: 1.2,
        spaceBetween: 0,
        centeredSlides: true,
      }
    },
    // init: false,
    pagination: {
      el: '.swiper-pagination2',
      clickable: true,
      dynamicBullets: true

    },
    navigation: {
      nextEl: '.swiper-button-next2',
      prevEl: '.swiper-button-prev2'
    }
  });

  var swiperVideo = new Swiper('.swiperVideo', {
    speed: 600,
    //loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 5500,
      disableOnInteraction: true
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      320: {
        slidesPerView: 1.2,
        spaceBetween: 15,
          //centeredSlides: false,
      }
    },
    // init: false,
    pagination: {
      el: '.swiper-pagination-video',
      clickable: true,
      dynamicBullets: true

    },
    navigation: {
      nextEl: '.swiper-button-next2',
      prevEl: '.swiper-button-prev2'
    }
  });


var galleryTop = new Swiper('.gallery-top', {
    slidesPerView: 1,
    loop: true,
    loopFillGroupWithBlank: true,
    loopedSlides: 31,
    autoHeight: true
  });
  var galleryThumbs = new Swiper('.gallery-thumbs', {
    //direction: 'vertical',
    slideToClickedSlide: true,
    spaceBetween: 0,
    loopedSlides: 31,
    slidesPerView:8,
    loop: true,
    breakpoints: {
      1280: {
        slidesPerView:8,
        spaceBetween: 0
      },
      1024: {
        slidesPerView:5,
        spaceBetween: 0
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      320: {
        slidesPerView: 3,
        spaceBetween: 0
      }
    },
    navigation: {
      nextEl: ".swiper-tl-button-next",
      prevEl: ".swiper-tl-button-prev",
    },
  });
  galleryTop.controller.control = galleryThumbs;
  galleryThumbs.controller.control = galleryTop;

}
export { qpcSwiper };
